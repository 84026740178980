exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ayur-tea-js": () => import("./../../../src/pages/ayur-tea.js" /* webpackChunkName: "component---src-pages-ayur-tea-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-celebrating-avurudu-with-the-farming-community-js": () => import("./../../../src/pages/celebrating-avurudu-with-the-farming-community.js" /* webpackChunkName: "component---src-pages-celebrating-avurudu-with-the-farming-community-js" */),
  "component---src-pages-childrens-day-22-js": () => import("./../../../src/pages/childrens-day-22.js" /* webpackChunkName: "component---src-pages-childrens-day-22-js" */),
  "component---src-pages-comming-soon-js": () => import("./../../../src/pages/Comming_soon.js" /* webpackChunkName: "component---src-pages-comming-soon-js" */),
  "component---src-pages-commingsoon-js": () => import("./../../../src/pages/commingsoon.js" /* webpackChunkName: "component---src-pages-commingsoon-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-drinking-water-project-kataragama-js": () => import("./../../../src/pages/drinking-water-project–kataragama.js" /* webpackChunkName: "component---src-pages-drinking-water-project-kataragama-js" */),
  "component---src-pages-haritha-tea-js": () => import("./../../../src/pages/haritha-tea.js" /* webpackChunkName: "component---src-pages-haritha-tea-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inspiring-ethnic-harmony-through-gratitude-js": () => import("./../../../src/pages/inspiring-ethnic-harmony-through-gratitude.js" /* webpackChunkName: "component---src-pages-inspiring-ethnic-harmony-through-gratitude-js" */),
  "component---src-pages-kruthaguna-dawatana-js": () => import("./../../../src/pages/kruthaguna-dawatana.js" /* webpackChunkName: "component---src-pages-kruthaguna-dawatana-js" */),
  "component---src-pages-mihinthalaya-js": () => import("./../../../src/pages/mihinthalaya.js" /* webpackChunkName: "component---src-pages-mihinthalaya-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-privacypolicy-js": () => import("./../../../src/pages/privacypolicy.js" /* webpackChunkName: "component---src-pages-privacypolicy-js" */),
  "component---src-pages-promotional-page-js": () => import("./../../../src/pages/promotional-page.js" /* webpackChunkName: "component---src-pages-promotional-page-js" */),
  "component---src-pages-sadahamsathkara-inner-page-js": () => import("./../../../src/pages/sadahamsathkara-inner-page.js" /* webpackChunkName: "component---src-pages-sadahamsathkara-inner-page-js" */),
  "component---src-pages-sharing-the-joys-christmas-with-friendly-chat-js": () => import("./../../../src/pages/sharing-the-joys-christmas-with-friendly-chat.js" /* webpackChunkName: "component---src-pages-sharing-the-joys-christmas-with-friendly-chat-js" */),
  "component---src-pages-termsandconditions-js": () => import("./../../../src/pages/termsandconditions.js" /* webpackChunkName: "component---src-pages-termsandconditions-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-watawala-scholarship-programme-2020-js": () => import("./../../../src/pages/watawala-scholarship-programme-2020.js" /* webpackChunkName: "component---src-pages-watawala-scholarship-programme-2020-js" */),
  "component---src-pages-watawala-tea-joins-the-fight-against-covid-19-js": () => import("./../../../src/pages/watawala-tea-joins-the-fight-against-covid-19.js" /* webpackChunkName: "component---src-pages-watawala-tea-joins-the-fight-against-covid-19-js" */),
  "component---src-pages-watawala-tea-js": () => import("./../../../src/pages/watawala-tea.js" /* webpackChunkName: "component---src-pages-watawala-tea-js" */),
  "component---src-pages-watawala-tea-rings-in-children-day-js": () => import("./../../../src/pages/watawala-tea-rings-in-children-day.js" /* webpackChunkName: "component---src-pages-watawala-tea-rings-in-children-day-js" */),
  "component---src-pages-wawanna-js": () => import("./../../../src/pages/wawanna.js" /* webpackChunkName: "component---src-pages-wawanna-js" */),
  "component---src-template-inner-page-js": () => import("./../../../src/template/inner-page.js" /* webpackChunkName: "component---src-template-inner-page-js" */)
}

